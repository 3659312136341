.profile-card {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    max-width: 300px;
    /* margin: auto; */
    text-align: center;
    font-family: arial;
    background-color: #fafafa;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  .profile-title {
    color: grey;
    font-size: 18px;
  }
  
  button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .profile-card-a {
    /* padding: 8px 0px 8px 32px; */
    text-decoration: none;
    font-size: 25px;
    color: black;
    transition: 0.3s;
  }